import React from 'react';
import { PostProvider } from '../lib/PostContext';
import Posts from './Posts';

const PostContainer = ({ blog, children, sliceStart, sliceEnd }) => {

  return (
        <PostProvider blog_slug={blog}>
          <Posts sliceStart={sliceStart} sliceEnd={sliceEnd}>
              {children}
          </Posts>
        </PostProvider>
  )
}

export default PostContainer;
