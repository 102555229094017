import React from 'react';
import { Grid, Header } from 'semantic-ui-react'

const HeroMeta = () =>
    <Grid centered style={{paddingTop: '2em'}}>
        <Grid.Column tablet={10} computer={7} verticalAlign="middle">
            <Header as='h2' inverted>
              Embedded with Senator Cory Booker coast to coast on his quest to become the next POTUS
            </Header>
        </Grid.Column>
    </Grid>

export default HeroMeta;
