import React from 'react';
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'


const Nav = ({ mobile=false, ...props }) => {
    if (mobile){
        return <MobileNav {...props}/>
    }
    return <DesktopNav {...props}/>
}

export default Nav;
