import React from 'react';
import { Card, Responsive, Divider } from 'semantic-ui-react'

import Posts from '../../Posts/Posts'
import { PostProvider } from '../../lib/PostContext';
import ArchivedPost, { SideArchivedPostCard } from '../../components/Posts/summary/ArchivedPost'
import FeaturedPost from '../../components/Posts/summary/FeaturedPost'
import RecentPost from '../../components/Posts/summary/RecentPost'

import DisplayPostVideo from '../../Media/DisplayPostVideo'
import DisplayPostStory from '../../Media/DisplayPostStory'

const PostDisplay = ({ post }) => {
    return (
        <FeaturedPost post={post}>
          <React.Fragment>
            <DisplayPostVideo media={post.media}/>
            <DisplayPostStory media={post.media} summary={post.summary}/>
          </React.Fragment>
        </FeaturedPost>
    )
}

const MobileArchivedPosts = () => {
    return (
        <Card.Group itemsPerRow={2} centered>
            <Posts sliceStart={3}>
                <SideArchivedPostCard />
            </Posts>
        </Card.Group>
    )
}

const TabletArchivedPosts = () => {
    return (
        <Card.Group itemsPerRow={3} centered>
            <Posts sliceStart={3}>
                <SideArchivedPostCard />
            </Posts>
        </Card.Group>
    )
}

const DesktopArchivedPosts = () => {
    return (
        <Card.Group itemsPerRow={3} centered>
            <Posts sliceStart={3}>
                <ArchivedPost />
            </Posts>
        </Card.Group>
    )
}


const ResponsiveArchivedPost = () => {
    return(
        <React.Fragment>
            <Responsive as={MobileArchivedPosts} {...Responsive.onlyMobile} />
            <Responsive as={TabletArchivedPosts} {...Responsive.onlyTablet} />
            <Responsive as={DesktopArchivedPosts} {...Responsive.onlyComputer} />
        </React.Fragment>
    )
}

const BookerContent = () => {
    return (
        <PostProvider blog_slug={'the-booker-beat'}>
            <React.Fragment>
                <Card.Group className='post-cards' centered>
                    <Posts blog='the-booker-beat' sliceStart={0} sliceEnd={1}>
                        <PostDisplay/>
                    </Posts>
                </Card.Group>
                <Divider />

                <Card.Group className='post-cards' itemsPerRow={2}>
                    <Posts blog='the-booker-beat' sliceStart={1} sliceEnd={3}>
                        <RecentPost/>
                    </Posts>
                </Card.Group>

                <Divider />
                <ResponsiveArchivedPost />


            </React.Fragment>
        </PostProvider>
    )
}

export default BookerContent;
