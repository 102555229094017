import React from 'react';
import { Card } from 'semantic-ui-react'

import PostContainer from '../../Posts/PostContainer'
import RecentPost from '../../components/Posts/summary/RecentPost'


const MobileArchivedPosts = () => {
    return (
        <Card.Group className='post-cards' itemsPerRow={2}>
            <PostContainer blog='reporters-notebook'>
                <RecentPost />
            </PostContainer>
        </Card.Group>
    )
}

const ReportersNotebookContent = (props) => {
  return (
    <React.Fragment>
        <MobileArchivedPosts />
    </React.Fragment>
    )
}

export default ReportersNotebookContent;
