import React from 'react';
import { Link } from "react-router-dom";

const Headline = ({ text, id, style }) => {
  if (id){
    return <Link to={'/posts/'+ id} style={style}>{text}</Link>
  } else {
    return <h2 style={style}>{text}</h2>
  }
}

export default Headline;
