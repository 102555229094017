import React from 'react';

let ThemeContext;
const { Provider, Consumer } = ThemeContext = React.createContext();

const headline_base = {
    fontSize: '1em',
    lineHeight: '1.2em',
    textTransform: 'uppercase'
}


const siteThemeStyles = {
    headline_base:{
        fontSize: '1em',
        lineHeight: '1.2em',
        textTransform: 'uppercase'
    },
    featured_headline: {
        fontSize: '1.7em'
    },
    byline: {
        base:{
            fontSize: '.9em',
            color: 'grey',
            fontStyle: 'italic'
        },
        pub_date: {
            float: 'left'
        },
        author:{
            float: 'right'
        }
    },
    headline: {
        featured: {
            ...headline_base,
            fontSize: '1.7em',
        },
        sidemenu: {
            ...headline_base,
            fontSize: '1em'
        },
        detail: {
            ...headline_base,
            fontSize: '1.7em',
            lineHeight: '1.2em',
            paddingTop: 10,
            paddingBottom: 5
        },
        recent: {
            ...headline_base,
            fontSize: '1.4em',
        },
        archived: {
            ...headline_base,
            fontSize: '1.2em',
        }
    },
    detail_headline: {
        fontSize: '2em',
        lineHeight: '1.2em',
        paddingTop: 10,
        paddingBottom: 10
    },
    recent_headline: {
        fontSize: '1.4em'
    },
    archived_headline: {
        fontSize: '1em'
    },
    body_text: {
        color: 'black',
        fontSize: '1.3em',
        paddingTop: 10
    }
}


const ThemeProvider = ({ children }) => {
    return(
        <Provider
            value={siteThemeStyles}>
            { children }
        </Provider>
    )
}

export { ThemeContext, Consumer as ThemeConsumer, ThemeProvider };
