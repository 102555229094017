import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ResponsiveContainer from './containers/ResponsiveContainer';
import ResponsiveContent from './containers/ResponsiveContent';
import Timeline from './components/Timeline';
import PostDetail from './Posts/PostDetail';
import { ThemeProvider } from './lib/ThemeContext';
import {
  Grid
} from 'semantic-ui-react'
import ReportersNotebookContent from './Blogs/ReportersNotebook/ReportersNotebookContent';
import BookerContent from './Blogs/BookerBeat/BookerContent';


const BlogPage = ({ children }) => {
  return(
    <Grid container className='blog-content'>
      <Grid.Column>
        {children}
      </Grid.Column>
    </Grid>
  )
}


function App() {
  return (
    <ThemeProvider>
      <Router>
        <ResponsiveContainer>
          <Switch>
            <Route path="/posts" component={PostDetail} />
            <Route path="/timeline" component={Timeline} />
            <Route path="/the-booker-beat" render={()=><BlogPage><BookerContent /></BlogPage>} />
            <Route path="/reporters-notebook" render={()=><BlogPage><ReportersNotebookContent /></BlogPage>} />
            <Route path="/" exact component={ResponsiveContent} />
          </Switch>
        </ResponsiveContainer>
      </Router>
    </ThemeProvider>
  );
}

export default App;
