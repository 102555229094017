import React from 'react';
import BookerDetail from '../Blogs/BookerBeat/BookerDetail';
import { Route, Switch } from "react-router-dom";
import { Grid } from 'semantic-ui-react';
import SideMenu from '../components/SideMenu';

const PostDetail = ({ match }) => {
  return (
    <Grid style={{marginBottom: 50}} centered columns='equal' container className="post-detail">
        <Grid.Column only='computer'>
            <Switch>
              <Route path={`${match.path}/:post_id`} component={SideMenu} />
            </Switch>
        </Grid.Column>
        <Grid.Column mobile={15} computer={12} >
          <Switch>
              <Route path={`${match.path}/:post_id`} component={BookerDetail} />
          </Switch>
        </Grid.Column>
    </Grid>
  )
}

export default PostDetail;
