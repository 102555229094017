import React from 'react';

const TIMELINE_URL = "https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=19hIZx4wvd_PSXoWUblARXa-7zqdzZVhOk4cYSzWSGuM&font=Default&lang=en&timenav_position=top&initial_zoom=1&height=550"

const Timeline = ({ title="Cory Booker Timeline" }) => (
    <iframe
        src={TIMELINE_URL}
        title={title}
        width='100%' height='800'
        webkitallowfullscreen="true"
        mozallowfullscreen="true" allowFullScreen frameBorder='0'>
     </iframe>
)

export default Timeline;
