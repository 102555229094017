import React, { useContext } from 'react';
import { Header } from 'semantic-ui-react';
import moment from 'moment';
import { usePost } from '../../lib/hooks'
import PostText from '../../Media/PostText'
import PostImage from '../../Media/PostImage'
import VideoEmbed from '../../components/VideoEmbed';
import YouTube from '../../components/YouTube';
import Headline from '../../components/Headline';
import { ThemeContext } from '../../lib/ThemeContext';

const PostMedia = ({ media }) => {
  switch(media.media_type){
    case 'TXT':
      return <PostText media={media} />
    case 'VIDEO':
      if (media.iframe_src){
        return <VideoEmbed url={media.iframe_src} />
      } else if (media.youtube_embed){
        return <YouTube videoId={media.youtube_embed}/>
      } else {
        break;
      }
    case 'IMAGE':
      return <PostImage media={media} />
    default:
      return null;
  }
}



const BookerDetail = ({ match:{params:{ post_id }} }) => {
    const { detail_headline } = useContext(ThemeContext)
    const post = usePost(post_id)

    if (!post) return null;
  return(
    <React.Fragment>
        <div>
          <Headline text={post.headline} style={detail_headline}/>
        </div>
          <Header >
            <Header.Subheader>
              <span>{moment(post.published_date).utc().format("MMMM D, YYYY")}</span>
              {` | `}
              <span>{post.author_name}</span>
            </Header.Subheader>
          </Header>
            <div style={{ clear: 'both'}}>
              {post.media.map((med, inx)=>(
                <PostMedia key={inx} media={med} />
              ))}
            </div>
      </React.Fragment>
  )
}

export default BookerDetail;
