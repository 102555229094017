import React, { Component } from 'react';

import { TwitterTimelineEmbed } from 'react-twitter-embed';

export class TwitterFeed extends Component {
    render() {
        return (
            <TwitterTimelineEmbed
                  sourceType="list"
                  ownerScreenName="NJTVNews"
                  slug="booker-beat"
                  options={{height: 700}}
                />
        );
    }
}

export default TwitterFeed;
