import React from 'react';
import { Image } from 'semantic-ui-react';

const PostImage = ({ media }) => {
    let floated;
    switch(media.layout){
        case 'media-img-float-left':
            floated = 'left';
            break
        case 'media-img-float-right':
            floated = 'right';
            break
        default:
            break;
    }
  return (
    <Image src={media.image_url} size='large' floated={floated} style={{clear:'both'}}/>
  )
}

export default PostImage;
