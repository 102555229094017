import React, { useState } from 'react';
import _ from 'lodash';
import { usePosts } from './hooks';

let PostContext;
const { Provider, Consumer } = PostContext = React.createContext();


const PostProvider = ({ children, blog_slug }) => {
    const [ filter, setHeadlineFilter ] = useState()
    const posts = usePosts(blog_slug);
    const filteredHeadlines = filter ? _.filter(posts, { headline: filter}) : posts;
    return(
        <Provider
            value={{
                posts: filteredHeadlines,
                setHeadlineFilter
            }}>
            { children }
        </Provider>
    )
}

export { PostContext, Consumer as PostConsumer, PostProvider };
