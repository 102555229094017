import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  Visibility, Responsive
} from 'semantic-ui-react'
import Nav from '../../components/Nav'
import Footer from '../../components/Footer';

function DesktopContainer({ children }){

  const [ fixed, setFixed ] = useState(false)

    return (
      <React.Fragment>
        <Visibility
          once={false}
          offset={130}
          onBottomPassed={()=> setFixed(true)}
          onBottomPassedReverse={()=> setFixed(false)}
        >
          <Nav fixed={fixed}/>
        </Visibility>

        {children}

        <Responsive as={Footer} minWidth={Responsive.onlyTablet.maxWidth} />
      </React.Fragment>
    )
}


DesktopContainer.propTypes = {
  children: PropTypes.node,
}

export default DesktopContainer;
