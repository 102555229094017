import PropTypes from 'prop-types'
import React from 'react'
import DesktopContainer from './DesktopContainer'

const ResponsiveContainer = ({ children }) => (
  <DesktopContainer>{children}</DesktopContainer>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

export default ResponsiveContainer;
