import React from 'react';
import VideoEmbed from '../components/VideoEmbed';
import HeroPostMedia from '../components/Posts/HeroPostMedia'
import _ from 'lodash';

const DisplayPostVideo = ({ media }) => {
  let media_video = _.filter(media, ['media_type', 'VIDEO'])
  if (!media_video.length) return <HeroPostMedia media={media} />;
  return media_video.map((m, inx)=>(
    <VideoEmbed key={inx} url={m.iframe_src} placeholder={m.placeholder_img}/>
  ))
}

export default DisplayPostVideo;
