import React from 'react';
import {
  Segment
} from 'semantic-ui-react'
import "./Footer.css";

const Footer = (props) => {
  return (
    <footer>
      <Segment inverted className="gradient-fill footer-container">

      </Segment>
    </footer>
  )
}

export default Footer;
