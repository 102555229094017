import React, { useContext } from 'react';
import { PostContext } from '../lib/PostContext';
import p_head from '../images/PeaHead_LoadingBar.gif'
import { Image } from 'semantic-ui-react';

const Posts = ({ children, sliceStart, sliceEnd=-1 }) => {
  const { posts } = useContext(PostContext);
  if (!posts.length) return <Image src={p_head} centered/>

  const slicedPosts = sliceStart !== undefined ? posts.slice(sliceStart, sliceEnd) : posts;
  return slicedPosts.map((post, inx) =>{
    return React.cloneElement(children, {
      key: post.id,
      post: {
        ...post,
        index: inx
      }
    })
  })
}

export default Posts;
