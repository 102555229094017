import React, { useContext } from 'react';
import { Card, Image } from 'semantic-ui-react';
import DateFormat from '../../../common/DateFormat';
import { ThemeContext } from '../../../../lib/ThemeContext';
import { Link } from "react-router-dom";
import PostHeadline from '../../PostHeadline';

const RecentPost = ({ post, children, toggleMenu}) => {
  const { headline: { recent }, byline } = useContext(ThemeContext)
  return (
    <Card fluid as={Link} to={'/posts/'+ post.id}>
      {post.hero_img && <Image src={post.hero_img} wrapped ui={false}/>}
      <Card.Content>
        <Card.Header as={PostHeadline} text={post.headline} style={recent} />
        <Card.Meta style={{ ...byline.base }}>
          <span><DateFormat date={post.published_date} /></span> ~{` `}
          <span>{post.author_name}</span>
        </Card.Meta>
      </Card.Content>
      {children &&
        <Card.Content extra>
          { children }
        </Card.Content>
      }
    </Card>

  )
}

export default RecentPost;
