import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { ThemeContext } from '../../../lib/ThemeContext';

const PostHeadline = ({ text, id, style }) => {
  const { headline } = useContext(ThemeContext)

  if (id){
    return (
        <Link
            to={'/posts/'+ id}
            style={{...headline.base, ...style}}
        >
            {text}
        </Link>
    )
  } else {
    return <div
      style={{...headline.base, ...style}}
    >{text}</div>
  }
}

export default PostHeadline;
