import React from 'react';
import { NavLink } from "react-router-dom";
import {
  Menu,
  Sidebar,
  Image,
  Icon
} from 'semantic-ui-react'

import booker_beat_logo from '../../images/TheBookerBeat.svg'

const MobileNav = ({ handleSidebarHide, sidebarOpened }) => {
  return (
      <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={handleSidebarHide}
          vertical
          visible={sidebarOpened}
          className="gradient-fill"
        >
          <Menu.Item header>
            <Icon name='sidebar' onClick={handleSidebarHide}/>
            {sidebarOpened && <Image src={booker_beat_logo} size="small"/>}
          </Menu.Item>
          <Menu.Item as={NavLink} to="/" exact>Home</Menu.Item>
          <Menu.Item as={NavLink} to="/timeline">Timeline</Menu.Item>
        </Sidebar>
  )
}

export default MobileNav;
