import React, { useContext } from 'react';
import { Card } from 'semantic-ui-react';
import DateFormat from '../../../common/DateFormat';
import { ThemeContext } from '../../../../lib/ThemeContext';
import PostHeadline from '../../PostHeadline';

const FeaturedPost = ({ post, children }) => {
  const { headline:{ featured }, byline } = useContext(ThemeContext)
  return (
    <Card fluid>
       <Card.Content>
          <Card.Header as={PostHeadline} text={post.headline} id={post.id} style={featured} />
          <Card.Meta style={{ ...byline.base }}>
            <span><DateFormat date={post.published_date} /></span> ~{` `}
            <span>{post.author_name}</span>
          </Card.Meta>
       </Card.Content>
       <Card.Content extra>
        { children }
      </Card.Content>
    </Card>

  )
}

export default FeaturedPost;
