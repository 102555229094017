import React from 'react';
import {
  Segment,
  Menu,
  Container,
  Image,
  Responsive,
  Dropdown
} from 'semantic-ui-react'
import { Link, NavLink, withRouter } from "react-router-dom";
import HeroBanner from '../HeroBanner'
import HeroMeta from '../HeroBanner/HeroMeta'
import './Nav.css'
import booker_beat_logo from '../../images/TheBookerBeat.svg'



const DesktopNavMenu = ({ fixed }) => {
  return (
    <Menu
      fixed={fixed ? 'top' : null}
      inverted
      pointing={!fixed}
      secondary={!fixed}
      size='large'
      className={fixed ? "gradient-fill" : ""}
    >
      <Container>
          {fixed && <Menu.Item header><Image src={booker_beat_logo} size="small"/></Menu.Item>}
          <Menu.Item as={NavLink} to="/" exact>Home</Menu.Item>
          <Menu.Item as={NavLink} to="/timeline">Timeline</Menu.Item>
      </Container>
    </Menu>
  )
}

const blog_title = blog_slug => {
    switch(blog_slug){
        case '/the-booker-beat':
            return "The Booker Beat";
        case '/reporters-notebook':
            return "The Reporter's Notebook"
        case '/timeline':
            return "Timeline"
        default:
            return "The Booker Beat";
    }
}

const MobileNavMenu = ({ fixed, location }) => {

  return (
    <Menu
      fixed='top'
      inverted
      pointing
      secondary
      size='large'
      className="gradient-fill"
    >
      <Menu.Item header as={Link} to="/">
        <Image src={booker_beat_logo} size="tiny"/>
      </Menu.Item>
      <Dropdown item text={blog_title(location.pathname)} onChange={(_,data)=>{ console.log(data);}}>
        <Dropdown.Menu>
          <Dropdown.Item as={NavLink} selected={location.pathname === '/the-booker-beat'} to="/the-booker-beat" exact>The Booker Beat</Dropdown.Item>
          <Dropdown.Item as={NavLink} selected={location.pathname === '/reporters-notebook'} to="/reporters-notebook" exact>The Reporter's Notebook</Dropdown.Item>
          <Dropdown.Item as={NavLink} selected={location.pathname === '/timeline'} to="/timeline">Timeline</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  )
}

const MobileNavWithRouter = withRouter(MobileNavMenu)


const DesktopNav = ({ fixed }) => {
  return (
      <Segment
        inverted
        textAlign='center'
        className="gradient-fill navigation"
      >

        <Responsive as={HeroBanner} minWidth={Responsive.onlyComputer.minWidth} />
        <Responsive as={HeroMeta} minWidth={Responsive.onlyComputer.minWidth} />
        <Responsive as={DesktopNavMenu} fixed={fixed} minWidth={Responsive.onlyComputer.minWidth} />

        <Responsive as={MobileNavWithRouter} fixed={fixed} maxWidth={Responsive.onlyTablet.maxWidth} />


      </Segment>
  )
}

export default DesktopNav;
