import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

const DEV_ENV = process.env.NODE_ENV === 'development'

const slugify = text =>
    text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w-]+/g, '')       // Remove all non-word chars
        .replace(/--+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');


const blog_title = blog_slug => {
    switch(blog_slug){
        case 'the-booker-beat':
            return "The Booker Beat";
        case 'reporters-notebook':
            return "The Reporter's Notebook"
        default:
            return null;
    }
}

export const usePostGA = (post) => {
    useEffect(()=>{
      if (post && !DEV_ENV){
        ReactGA.pageview(`/posts/${slugify(post.headline)}`,[], blog_title(post.blog));
      }
    },[post])
}


export const useBlogGA = (blog) => {
    useEffect(()=>{
      if (blog && !DEV_ENV){
        ReactGA.pageview(`/`,[], blog_title(blog));
      }
    },[blog])
}


export const useReadMoreGAEvent = post => {
    const [showMore, setVisible] = useState(false);

    useEffect(()=>{
        if (post && !DEV_ENV){
            if (showMore){
                ReactGA.event({
                  category: 'Interaction',
                  action: 'Read More Button',
                  label: post.posts_title
                });
            } else {
                ReactGA.event({
                  category: 'Interaction',
                  action: 'Show Less Button',
                  label: post.posts_title
                });
            }
        }
    }, [post, showMore])

    return [showMore, setVisible]
}
