import React from 'react';
import PropTypes from 'prop-types'
import { Image, Container, Grid } from 'semantic-ui-react'
import { Link } from "react-router-dom";

import booker_beat_logo from '../../images/TheBookerBeat.svg'
import spotlight_logo from '../../images/NJSpotlight_landing_ongradient.svg'
import njtv_logo from '../../images/NJTVNews_landing_ongradient.svg'


const HeroBanner = () => (
  <Container>
    <Grid centered columns='equal' verticalAlign="middle" style={{paddingTop: 30}}>
        <Grid.Column>
            <a href="https://www.njspotlight.com" target="_blank" rel="noopener noreferrer">
                <Image src={spotlight_logo}  size='small' floated='right'/>
            </a>
        </Grid.Column>
        <Grid.Column width={6}>
            <Link to="/">
                <Image src={booker_beat_logo} size='large' centered />
            </Link>
        </Grid.Column>
        <Grid.Column>
            <a href="https://www.njtvonline.org/news" target="_blank" rel="noopener noreferrer">
              <Image src={njtv_logo} size='small'/>
            </a>
        </Grid.Column>
    </Grid>
  </Container>
)

HeroBanner.propTypes = {
  mobile: PropTypes.bool,
}
export default HeroBanner;
