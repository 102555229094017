import React, { useContext, useState }  from 'react';
import { Button, Header } from 'semantic-ui-react'
import { PostImageGallery } from '../../ImageGallery/ImageGallery'
import { PostProvider, PostContext } from '../../../lib/PostContext';
import { Link } from "react-router-dom";
// import PostContainer from '../../../Posts/PostContainer'
// import ReportersSummary from '../../../Blogs/ReportersNotebook/ReportersSummary';
import './TheNotebook.css'


const IndexedPostContent = ({ children }) => {
    const { posts } = useContext(PostContext);
    const [index, setIndex] = useState(0);

    const selectedPost = posts[index];
    if (!selectedPost) return null;
    return React.cloneElement(children, {
      key: selectedPost.id,
      post: {
        ...selectedPost,
        index
      },
      post_length:posts.length,
      setIndex
    })

}


const NotebookContent = ({ post, setIndex, post_length }) => {
    const nextPage = (_, data) => {
        if (post.index >= post_length - 1) return post_length
        setIndex(post.index+1)
    }

    const lastPage = () => {
        if (post.index <= 0) return 0
        setIndex(post.index-1)
    }
    return (
        <div className='the-notebook'>
            <div className="the-notebook-header"></div>
            <div className='the-notebook-wrapper'>
                <h2>Reporter's Notebook</h2>
                <Header as='h3' textAlign='center' inverted>
                    {post.headline}
                    <Header.Subheader>
                        ~ {post.author_name}
                    </Header.Subheader>
                </Header>
                <div className='the-notebook-content'>
                    <Link to={`/posts/${post.id}`}>
                        <PostImageGallery post={post}/>
                    </Link>
                </div>
                <div className='the-notebook-menu'>
                </div>
                <div className='the-notebook-footer'>
                    <Button.Group basic>
                        <Button icon='left chevron' disabled={post.index === 0} onClick={() =>{ lastPage() }}/>
                        <Button secondary>{post.index + 1}</Button>
                        <Button icon='right chevron' disabled={post.index === post_length - 1} onClick={()=>{ nextPage() }}/>
                    </Button.Group>
                </div>
            </div>
        </div>
    )
}


const TheNotebook = (props) => {
  return (
    <PostProvider blog_slug='reporters-notebook'>
        <IndexedPostContent>
            <NotebookContent />
        </IndexedPostContent>
    </PostProvider>
  )
}

export default TheNotebook;
