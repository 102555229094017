import React from 'react';
import { Embed } from 'semantic-ui-react'

const VideoEmbed = ({ url, placeholder }) => {
  return (
    <Embed
      active={true}
      placeholder={placeholder}
      url={url}
      aspectRatio="16:9"
    />
  )
}

export default VideoEmbed;
