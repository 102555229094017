import React, { useContext } from 'react';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';
import nl2br from 'react-nl2br';
import { ThemeContext } from '../lib/ThemeContext';
import { useReadMoreGAEvent } from '../lib/hooks'

const DisplayPostStory = ({ media, style, summary, moreBtn=true }) => {
    const [showMore, setVisible] = useReadMoreGAEvent();
    const { body_text } = useContext(ThemeContext)
    let media_story = _.filter(media, ['media_type', 'TXT'])


    if (moreBtn && !showMore && media_story.length && summary){
        return (
            <div>
                <p style={{...body_text, ...style}}>{summary}</p>
                <Button fluid primary size='small' onClick={()=>setVisible(true)}>More</Button>
            </div>
        )
    } else {
        return (
            <div>
                {media_story.map((m, inx)=>(
                    <p key={inx} style={{...body_text, ...style}}>{nl2br(m.content)}</p>
                ))}
                {moreBtn && !!media_story.length && summary &&
                    <Button fluid default size='small' onClick={()=>setVisible(false)}>Less</Button>
                }
            </div>
        )
    }

}

export default DisplayPostStory;
