import { useReducer } from 'react';

const useImageIndexCounter = (images) => {

  const initialState = {count: 0};

  const [ inx, dispatch ] = useReducer((state, action) => {
    switch (action.type) {
      case 'increment':
        if (state.count < images.length - 1){
          return {count: state.count + 1};
        } else {
          return state;
        }
      case 'decrement':
        if (state.count <= images.length && state.count > 0){
          return {count: state.count - 1};
        } else {
          return state;
        }
      default:
        throw new Error();
    }
  }, initialState)

  return [ inx.count, images[inx.count], dispatch ]
}

export default useImageIndexCounter;
