import React from 'react';
import nl2br from 'react-nl2br';
import { Header } from 'semantic-ui-react';

const styles = {
    fontSize: '1.3em'
}

const PostText = ({ media, style }) => {

    if (media.headline){
        switch(media.layout){
            case 'inline-strong':
                return(
                    <p style={{...styles, ...style}}>
                        <strong>{media.headline}</strong>{` `}
                        {nl2br(media.content)}
                    </p>
                )
            default:
                return (
                    <React.Fragment>
                        <Header as='h2'>{media.headline}</Header>
                        <p style={{...styles, ...style}}>{nl2br(media.content)}</p>
                    </React.Fragment>
                )
        }
    }
  return <p style={{...styles, ...style}}>{nl2br(media.content)}</p>
}

export default PostText;
