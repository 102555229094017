import React from 'react';
import { Image, Icon } from 'semantic-ui-react';
import { filterMedia, useImageIndexCounter } from '../../lib/hooks';
import './ImageGallery.css'


const ImageGallery = ({ images }) => {

    const [ inx, { image_url, content }, dispatch ] = useImageIndexCounter(images);
    if (images.length > 1){
        return (
          <div className='media-images'>
            <div className='img-gallery'>
              { inx < (images.length - 1)  && <div onClick={()=>dispatch({ type: 'increment' })} className='img-gallery-btn right-btn'>
                <Icon name='angle right' />
               </div> }
                    <Image src={image_url} size='medium'/>
              { inx > 0 && <div onClick={()=>dispatch({ type: 'decrement' })} className='img-gallery-btn left-btn'>
                 <Icon name='angle left' />
              </div>}
            </div>
              { content && <p className='img-caption'>{content}</p>}
          </div>
        )
    } else {
        return (
          <div className='media-images'>
              <Image src={image_url} size='medium'/>
            { content && <p className='img-caption'>{content}</p>}
          </div>
        )
    }
}

export const PostImageGallery = ({ post }) => {
    const images = filterMedia(post.media, 'IMAGE')
    if (!images) return null;
    return <ImageGallery images={images} />
}

export default ImageGallery;
