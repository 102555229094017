import React, {createRef} from 'react';
import { Responsive, Grid, Segment, Ref, Sticky, Rail } from 'semantic-ui-react'
import { getWidth } from '../../lib/utils';

import TwitterFeed from '../../components/TwitterFeed'
import TheNotebook from '../../components/interactive/TheNotebook'
import BookerContent from '../../Blogs/BookerBeat/BookerContent';

const MobileContent = () => (
    <Grid>
        <Grid.Column>
            <Segment basic style={{paddingTop:50}}>
                <BookerContent />
            </Segment>
        </Grid.Column>
    </Grid>
)
class DesktopContent extends React.Component{
    contextRef = createRef()
    render(){
        return (
            <Grid centered columns={2}>
                <Grid.Column>
                    <Ref innerRef={this.contextRef}>
                        <Segment basic  className='post-container'>
                            <BookerContent />
                            <Rail position='left'>
                                <Sticky context={this.contextRef} offset={115}>
                                    <TheNotebook />
                                </Sticky>
                            </Rail>

                            <Rail position='right'>
                                <Sticky context={this.contextRef} offset={115}>
                                    <TwitterFeed />
                                </Sticky>
                            </Rail>

                        </Segment>
                    </Ref>
                </Grid.Column>
            </Grid>

        )
    }
}

const ResponsiveContent = ({ mobile=false, ...props }) => {
    return (
        <div>
            <Responsive
                getWidth={getWidth}
                minWidth={Responsive.onlyTablet.maxWidth}
              >
                <DesktopContent {...props}/>
            </Responsive>
            <Responsive
                getWidth={getWidth}
                maxWidth={Responsive.onlyTablet.maxWidth}
            >
                <MobileContent {...props}/>
            </Responsive>
        </div>
    )
}

export default ResponsiveContent;
