import React, { useContext } from 'react';
import { Card, Image, Reveal } from 'semantic-ui-react';
import DateFormat from '../../../common/DateFormat';
import { ThemeContext } from '../../../../lib/ThemeContext';
import { Link } from "react-router-dom";

import PostHeadline from '../../PostHeadline';


const overlay_text_styles = {
  color: 'white',
  padding: 10,
  lineHeight: '1.6em',
  fontSize: '1em',
  textTransform: 'uppercase'
}


const PostHeadlineImage = ({ post }) => {
    return (
        <Image>
          <PostHeadline text={post.headline} style={overlay_text_styles} />
          <span style={{ ...overlay_text_styles, color: 'rgba(255, 255, 255, 0.6)', fontSize: '.9em'}}>
            <DateFormat date={post.published_date} /> <br />
            ~ {post.author_name}
          </span>
        </Image>
     )
}

const PostThumbReveal = ({ post, children }) => {
    return (
        <Card
          as={Link}
          to={`/posts/${post.id}`}
           className='gradient-fill'
        >
            <Card.Content>
            {post.hero_img ?
                <Reveal animated='move up'>
                    <Reveal.Content visible>
                        <Image src={post.hero_img} />
                    </Reveal.Content>
                    <Reveal.Content hidden>
                        {children}
                    </Reveal.Content>
                </Reveal> : children }
            </Card.Content>
        </Card>
     )
}

const ArchivedPost = ({ post }) => {
  if (!post) return null;
  return (
   <PostThumbReveal post={post}>
       <PostHeadlineImage post={post} />
   </PostThumbReveal>
  )
}



export const SideArchivedPostCard = ({ post, children, showImg=true }) => {
  const { headline: { archived }, byline } = useContext(ThemeContext)
  return (
    <Card as={Link} to={'/posts/'+ post.id} >
      {(showImg && post.hero_img) &&
        <Image src={post.hero_img} wrapped ui={false}>
        </Image>
      }
        <Card.Content >
          <Card.Header as={PostHeadline} text={post.headline} style={archived} />
          <Card.Meta style={{ ...byline.base }}>
            <span><DateFormat date={post.published_date} /></span> ~{` `}
            <span>{post.author_name}</span>
          </Card.Meta>
        </Card.Content>

      {children &&
        <Card.Content extra>
          { children }
        </Card.Content>
      }
    </Card>

  )
}

export default ArchivedPost;
