import React, { useContext } from 'react';
import { usePost } from '../../lib/hooks'
import PostContainer from '../../Posts/PostContainer'
import { NavLink } from "react-router-dom";
import { Header, Divider, Segment } from 'semantic-ui-react';
import moment from 'moment';
import { ThemeContext } from '../../lib/ThemeContext';


const SideHeadline = ({ post }) => {
    const { headline:{ sidemenu } } = useContext(ThemeContext)

    return (
        <Header
            as={NavLink}
            to={'/posts/'+ post.id}
            activeStyle={{
                fontWeight: "bold",
                color: "blue"
            }}
            style={sidemenu}
        >
            { post.headline }<br/>
            <Header.Subheader>
                {moment(post.published_date).utc().format("MMMM D, YYYY")}
            </Header.Subheader>
            <Divider />
        </Header>
    )
}

const BlogTitle = ({ slug }) => {
    switch(slug){
        case 'the-booker-beat':
            return `The Booker Beat`
        case 'reporters-notebook':
            return `The Reporters Notebook`
        default:
            return null;
    }
}


const SideMenu = ({ match:{ params:{ post_id }} }) => {
  const post = usePost(post_id)
  if (!post) return null;
  return (
    <Segment.Group>
        <Segment inverted color='green'>
            <Header style={{ fontSize: '1.4em'}}>
                <BlogTitle slug={post.blog}/>
            </Header>
        </Segment>
            <Segment>
        <PostContainer blog={post.blog}>
                <SideHeadline />
        </PostContainer>
            </Segment>
    </Segment.Group>
  )
}

export default SideMenu;
