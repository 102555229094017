import { useContext, useState, useEffect, useDebugValue } from 'react';
import _ from 'lodash';
import { PostContext } from './PostContext';
import { usePostGA, useBlogGA, useReadMoreGAEvent } from './useGA';
import useImageIndexCounter from './useImageIndexCounter';

const DEBUG = false;
const LOCAL_API_URL = 'http://localhost:5000/api/posts/'
const AWS_API_URL = 'https://www.njs-pmnj.org/api/posts/'
// const API_URL = 'https://www.njs-pmnj.org/api/posts/'
const API_URL = DEBUG ? AWS_API_URL : process.env.NODE_ENV === 'development' ? LOCAL_API_URL : AWS_API_URL;

const useMedia = () => {
  const { posts } = useContext(PostContext);
  const postmedia = _.map(posts, (p)=>_.pick(p, ['media']))
  const [ media ] = _.map(postmedia, (m)=>m.media)
  return media || [];
}

const useImageMedia = () => {
  const media = useMedia();
  if (!media) return null;
  return _.filter(media, ['media_type', 'IMAGE'])
}

const apiFetch = (id) =>
  fetch(API_URL + 'get/' + id)
    .then(d=>d.json())
    .catch(console.log)

const usePost = id => {
  const [post, setPost] = useState()

  useEffect(()=>{
    apiFetch(id)
      .then(res=>{
        setPost(res)
      })
  }, [id])

  usePostGA(post)

  return post
}

const usePostMedia = (id, type) => {
    const post = usePost(id);
    const media = _.filter(post.media, ['media_type', type])
    return [post, media]
}

const filterMedia = (media, type) => {
    return _.filter(media, ['media_type', type])
}

const usePosts = (blog) => {
    const [ posts, setPosts ] = useState([])
    useEffect(()=>{
        fetch(API_URL + 'list/' + blog)
            .then(d=>d.json())
            .then(res=>{
                setPosts(res)
            })
    }, [blog])
    useBlogGA(blog)
    useDebugValue(blog);
    return posts;
}



export {
    useReadMoreGAEvent,
    useMedia,
    useImageMedia,
    usePost,
    usePosts,
    usePostMedia,
    filterMedia,
    useImageIndexCounter
};
